* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  scroll-padding-top: 100px;
  -webkit-user-drag: none;
}
body {
  font-family: "DM Sans", sans-serif;
  overflow-x: hidden;
}
/* Color */
:root {
  --white: #ffffff;
  --black: #09090a;
  --primary: #be1e42;
  --secondary: #212326;
  --tertiary: #8e8e8e;
  --quaternary: #f5f5f5;
  --darkbg: #e1d4f8;
  --lightbg: #efefef;
}
a {
  text-decoration: none;
}
.bgprimary {
  background-color: var(--primary);
}
.darkbg {
  background-color: var(--darkbg);
}
.lightbg {
  background-color: var(--lightbg);
}

/* Button */
.primarybtn {
  background-color: var(--primary);
  border-radius: 10px;
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  padding: 12px 26px;
  border: none;
  border: 1px solid var(--primary);
}
.primarybtn:hover {
  transition: 0.3s ease-in;
  color: var(--primary);
  background-color: transparent;
  border: 1px solid var(--primary);
}
.secondarybtn {
  color: var(--primary);
  background-color: transparent;
  border: 1px solid var(--primary);
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 26px;
}
.secondarybtn:hover {
  background-color: var(--primary);
  color: var(--white);
  transition: 0.3s ease-in;
}
.lighbgbtn {
  background-color: var(--primary);
  border-radius: 10px;
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  padding: 12px 26px;
  border: none;
  border: 1px solid var(--primary);
}
.whitebtn {
  background-color: var(--white);
  border-radius: 10px;
  color: var(--primary);
  font-size: 16px;
  font-weight: 600;
  padding: 12px 26px;
  border: none;
}

/* bg */
.themebg {
  background-color: var(--primary);
}
.lightbg {
  background-color: var(--lightbg);
}

/* Navbar */

/* Humberg */
.hamburger {
  width: 2.5rem;
  height: 1.5rem;
  position: relative;
  cursor: pointer;
  line-height: 5rem;
  z-index: 1500;
}
.hamburger .line {
  width: 100%;
  height: 1.5px;
  background-color: var(--primary);
  position: absolute;
  display: block;
  right: 0;
  transition: all 650ms;
}
.hamburger .line:nth-child(1) {
  top: 0;
}
.hamburger .line:nth-child(2) {
  top: 50%;
  margin-top: -0px;
  width: 100%;
}
.hamburger .line:nth-child(3) {
  top: 100%;
  margin-top: 0px;
  width: 100%;
}
.hamburger.active .line:nth-child(1) {
  transform: translateY(0.8rem) rotate(45deg);
  width: 28px;
  top: -1px;
}
.hamburger.active .line:nth-child(2) {
  opacity: 0;
  margin-top: -1px;
}
.hamburger.active .line:nth-child(3) {
  transform: translateY(-0.8rem) rotate(-45deg);
  width: 28px;
  margin-top: 0px;
}

.inner-header {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
.inner-headerColor {
  background-color: var(--black);
}
.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
}
.main-header ul {
  margin-bottom: 0;
}

.logo > a {
  text-decoration: none;
  color: black;
  font-weight: 500;
  font-size: 1.5rem;
}
.bars > svg {
  cursor: pointer;
}
.navbar-items > ul {
  display: flex;
  list-style: none;
}

.navbar-items ul li {
  padding: 0px 16px;
  position: relative;
}
.navbar-items ul li a {
  text-decoration: none;
  color: var(--secondary);
  font-size: 1rem;
  font-weight: 500;
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.navbar-items ul li a:hover {
  color: var(--primary);
}
.activenav a {
  border-bottom: 2px solid var(--primary);
  color: var(--primary) !important;
}
.navbar-items ul li a::before {
  content: "";
  transition: 1s ease;
  position: absolute;
  width: 0;
  border-bottom: 2px solid var(--primary);
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
}

.navbar-items ul li a:hover::before {
  width: 100%;
}

.bars {
  display: none;
}

@media only screen and (max-width: 900px) {
}

/* USER */
.usermain {
  background-color: var(--quaternary);
  padding: 42px 0;
}
.user_detail {
  display: flex;
  justify-content: space-around;
}
.user_numbersub {
  display: flex;
  align-items: center;
  gap: 20px;
}
.user_numbersub img {
  width: 80px;
}
.user_numbersub h5 {
  color: var(--secondary);
  font-size: 32px;
  font-weight: 600;
}
.user_numbersub p {
  font-size: 20px;
  font-weight: 400;
  color: var(--tertiary);
  margin-bottom: 0;
}
/* USER */

/* HEROSEC */
.homebg {
  background-image: url(./assets/Images/herobg.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.herosec_main {
  padding: 200px 0;
}
.heroimg img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 700px;
}
.heroanimation {
  transform: translateY(30px);
  animation-name: fadeup;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}
.hero-sec h1 {
  font-size: 52px;
  font-weight: 700;
  color: var(--white);
}
@keyframes fadeup {
  from {
    transform: translateY(30px);
  }
  to {
    transform: translateY(0px);
  }
}
.hero-sec h1 span {
  color: var(--primary);
}
.hero-sec p {
  font-size: 16px;
  font-weight: 400;
  color: var(--tertiary);
}
.twobutton {
  padding-top: 15px;
  display: flex;
  gap: 20px;
}
.twobutton .primarybtn,
.secondarybtn {
  display: inline-block;
  padding: 16px 30px;
}
@keyframes imganimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Commantext */
.commantext h4 {
  color: var(--secondary);
  font-size: 40px;
  font-weight: 600;
}
.commantext h4 span {
  color: var(--primary);
}
.commantext p {
  font-size: 16px;
  font-weight: 400;
  color: var(--tertiary);
}
.commanOther h4 {
  color: var(--secondary);
  font-size: 30px;
  font-weight: 700;
}
.commanOther p {
  font-size: 16px;
  font-weight: 400;
  color: var(--tertiary);
}
.commanOther p a {
  color: var(--primary);
}
.policy_text .commanOther h4 {
  font-size: 20px;
}

.contactus_text h4 {
  font-size: 28px;
  font-weight: 700;
  color: var(--black);
  margin-bottom: 18px;
}
.contactus_text p {
  margin-bottom: 5px;
  font-size: 16px;
  color: var(--black);
}
.contactus_text p a {
  color: var(--primary);
}
.contact_main {
  padding: 50px 0;
}
.emailspace {
  margin-bottom: 40px !important;
}
/* startmain */
.startmain {
  padding: 80px 0;
}
/* startmain */

/* Tradingsec */
.futureanimation .commantext {
  transform: scale(0);
  animation: zoomAndDarken 2s forwards;
  opacity: 0;
}

@keyframes zoomAndDarken {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.futuresec_main {
  padding: 100px 0;
}
.tradingsec {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.trading_sub {
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: center;
  border-radius: 40px;
  padding: 42px 30px 36px;
  opacity: 0;
  background-color: var(--quaternary);
}
.futureanimation .trading_sub {
  transform: scale(0.8);
  animation: cardscale 3s forwards;
}
@keyframes cardscale {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.trading_sub h5 {
  margin-bottom: 0;
  color: var(--secondary);
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.trading_sub p {
  margin-bottom: 0;
  color: var(--tertiary);
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

/* EARN */
.earnmain {
  padding: 60px 0 90px;
  background-image: url(./assets/Images/earnbg.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.earnmain .commantext h4 {
  color: var(--white);
}
.earn_card_main {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 350px);
  gap: 30px;
  justify-content: center;
}
.earn_card {
  text-align: center;
  padding: 25px;
  background: #17191c;
  border-radius: 24px;
  border: 1.2px solid #262626;
  position: relative;
}
.earn_card img {
  border-radius: 24px;
  /* height: 200px; */
}
.earn_card h5 {
  margin-top: 12px;
  color: var(--white);
  font-size: 18px;
  font-weight: 600;
}
.earn_card p {
  color: var(--white);
  font-size: 16px;
  font-weight: 400;
}
.earn_card .knowbtn {
  position: absolute;
  right: 0;
  left: 0;
  bottom: -25px;
}
/* EARN */

/* Accord */
.accordsec {
  padding: 10px 0 80px;
}
.accordsec .accordion-button:not(.collapsed) {
  background-color: var(--lightbg);
  color: var(--primary);
}
.accordsec .accordion-button:focus {
  box-shadow: none;
}
.accordsec .accordion-button::after {
  background-image: url(./assets/Images/plus.svg);
}
.accordion-button:not(.collapsed)::after {
  background-image: url(./assets/Images/minus.svg);
}
.accordsec .accordion-button {
  background-color: var(--quaternary);
  margin-bottom: 10px;
  border-radius: 20px;
}
.accordion .accordion-item {
  border: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 12px;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 12px;
}

/* Enjoy */
.enjoymain {
  margin-bottom: -72px;
}
.enjoysub {
  position: relative;
  border-radius: 20px;
  padding: 36px;
  box-shadow: 0px 0px 4px 0px #00000040;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 70px;
}
.enjoytext {
  z-index: 1;
  max-width: 400px;
}
.enjoytext h5 {
  color: var(--black);
  font-size: 24px;
  font-weight: 600;
}
.enjoytext p {
  color: var(--black);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}
.enjoytext button {
  border-radius: 10px;
}
.enjoymain .mainimg {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

/* PLAYCRAD */
.play_card {
  background-image: url(./assets/Images/startbg.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}
.play_card img {
  /* width: 200px; */
  border-radius: 100px 0 0px 0px;
}
.swiper {
  position: unset !important;
}
.swipermain {
  position: relative;
}
.swiper-pagination-bullet-active {
  background-color: var(--primary) !important;
}
.swiper-pagination {
  bottom: -30px !important;
}
.play_cardtext {
  padding: 20px;
  width: 50%;
}
.play_cardtext h4 {
  color: var(--black);
  font-size: 26px;
  font-weight: 600;
}
.play_cardtext p {
  color: var(--black);
  font-size: 16px;
  font-weight: 400;
}
/* PLAYCRAD */
/*  maximum_profirsec*/

.maximum_profirsec {
  padding: 90px 0;
}
/* maximum_profirsec */
/* Footer */
.footer {
  padding: 120px 0 60px;
  background-color: var(--primary);
}
.logodetail_main {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-bottom: 40px;
}
.logodetail_sub {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.logodetail_main h4 {
  margin-bottom: 0;
  color: var(--white);
  font-size: 30px;
  font-weight: 700;
}
.logodetail_main img {
  width: 100px;
}
.followus a {
  color: #d2d2d2;
}
.link-detail img {
  width: 225px;
}
.link-detail h6 {
  margin-bottom: 18px;
  color: var(--white);
  font-size: 18px;
  font-weight: 700;
}
.link-detail p {
  margin-bottom: 10px;
  color: var(--lightbg);
}
.link-detail p a {
  cursor: pointer;
  text-decoration: none;
  color: var(--lightbg);
  font-size: 16px;
  font-weight: 400;
}
.link-detail p a:hover {
  color: var(--white);
}
.contact {
  display: flex;
  align-items: center;
}
.contact p {
  margin-bottom: 0;
  color: #dee2ee;
  font-weight: 700;
}
.contact i {
  margin-right: 10px;
  color: var(--primary);
}
.contact:hover {
  color: var(--primary);
}
.copy-rightMain {
  background-color: var(--primary);
}
.copy-right {
  padding: 10px;
  border-top: 1px solid #d25d77;
  display: flex;
  align-items: center;
  justify-content: center;
}
.copy-right p {
  text-align: center;
  margin-bottom: 0;
  color: var(--white);
}

.swiper-container {
  position: relative;
}

.swiper-pagination {
  position: absolute;
  bottom: 10px; /* Adjust as needed */
  width: 100%;
  text-align: center;
  z-index: 10; /* Ensure it is above other elements */
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.modal-content {
  width: 500px;
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
}
.close-button {
  cursor: pointer;
}
.modal_header {
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.form_field {
  padding-bottom: 10px;
}
.form_field .form-control {
  padding: 10px;
}
.form_field .form-control:focus {
  border-color: #be1e424d;
  box-shadow: 0 0 0 0.25rem #be1e422b;
}
.error {
  color: red;
  font-size: 14px;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}
/* Modal */

.policy_text {
  padding: 100px 0 20px;
}

/* Media */

/* 1680 */
@media (max-width: 1700px) {
}

/* 1500 */
@media (max-width: 1680px) {
}

/* 1440 */
@media (max-width: 1449px) {
}

/* 1280 */
@media (max-width: 1439px) {
  .hero-sec h1 {
    font-size: 44px;
  }
  .twobutton {
    padding-top: 20px;
  }
  .commantext h4 {
    font-size: 36px;
  }
  .futuresec_main {
    padding: 85px 0;
  }
  .maximum_profirsec {
    padding: 70px 0;
  }
  .commanOther h4 {
    font-size: 28px;
  }
}

/* 992 */
@media (max-width: 1279px) {
  .nav-link {
    padding: 0.5rem 10px;
  }
  .logo img {
    width: 200px;
  }
  .herosec_main {
    padding: 116px 0;
  }
  .navbar-items ul li {
    padding: 0px 10px;
  }
  .heroimg img {
    width: 500px;
  }
  .hero-sec h1 {
    font-size: 38px;
  }
  .twobutton .primarybtn,
  .secondarybtn {
    padding: 10px 22px;
    font-size: 14px;
  }
  .twobutton {
    padding-top: 10px;
    gap: 10px;
  }
  .futuresec_main {
    padding: 60px 0;
  }
  .commantext h4 {
    font-size: 32px;
  }
  .trading_sub h5 {
    font-size: 18px;
  }
  .maximum_profirsec {
    padding: 60px 0;
  }
  .commanOther h4 {
    font-size: 24px;
  }
  .enjoytext h5 {
    font-size: 20px;
  }
  .link-detail img {
    width: 200px;
  }
  .user_numbersub h5 {
    font-size: 20px;
  }
  .user_numbersub p {
    font-size: 16px;
  }
  .usermain {
    padding: 15px 0;
  }
  .startmain {
    padding: 50px 0;
  }
  .enjoysub {
    padding: 22px;
    margin: 0 30px;
  }
  .enjoymain {
    margin-bottom: -60px;
  }
  .footer {
    padding: 100px 0 40px;
  }
  .logodetail_main h4 {
    font-size: 28px;
  }
  .logodetail_main img {
    width: 80px;
  }
  .earn_card_main {
    grid-template-columns: repeat(2, 315px);
    gap: 40px;
  }
  .play_card img {
    border-radius: 66px 0 0px 0px;
  }
  .play_cardtext {
    padding: 14px 20px;
  }
}

/* 768 */
@media (max-width: 991px) {
  /* nav */
  .bars {
    display: block;
  }
  .main-header {
    position: relative;
  }
  .active {
    left: 0 !important;
  }
  .navbar-items {
    transition: 1s ease;
    display: flex;
    align-items: center;
    position: fixed;
    top: 74px;
    left: -110%;
    width: 100%;
    /* background-color: var(--white); */
    backdrop-filter: blur(16px);
    z-index: 1;
  }
  .navbar-items ul {
    padding: 0px 15px 22px;
    flex-direction: column;
    justify-content: space-between;
  }
  .bars img {
    width: 35px;
  }
  .navbar-items ul li a {
    color: black;
  }
  .navbar-items ul li a::before {
    content: none;
  }
  /* Nav */
  .heroimg img {
    width: 350px;
  }
  .herosec_main {
    padding: 104px 0 182px;
  }
  .futuresec_main {
    padding: 30px 0;
  }
  .commantext h4 {
    font-size: 26px;
  }
  .tradingsec {
    grid-template-columns: repeat(2, 1fr);
    gap: 14px;
  }
  .trading_sub {
    padding: 20px;
    border-radius: 18px;
  }
  .tradingIcon img {
    width: 60px;
  }
  .trading_sub h5 {
    font-size: 16px;
  }
  .trading_sub p {
    margin-bottom: 0;
    font-size: 14px;
  }
  .commanOther h4 {
    font-size: 20px;
  }
  .maximum_profirsec {
    padding: 30px 0;
  }
  .mxprofit-hero img {
    width: 300px;
    padding-bottom: 20px;
  }
  .becomemain {
    padding: 36px 0;
  }
  /* .accordsec {
    padding: 10px 0;
  } */
  .user_numbersub img {
    width: 55px;
  }
  .user_numbersub {
    gap: 10px;
  }
  .user_numbersub h5 {
    font-size: 18px;
    margin-bottom: 0;
  }
  .startmain {
    padding: 30px 0;
  }
  .earnmain {
    padding: 32px 0 60px;
  }
  .accordsec {
    padding: 10px 0 18px;
  }
  .logodetail_main h4 {
    font-size: 24px;
  }
  .logodetail_main img {
    width: 60px;
  }
  .play_cardtext h4 {
    font-size: 20px;
  }
  .policy_text {
    padding-top: 70px;
  }
  .contact_main {
    padding: 0 0 20px;
  }
}

/* 576 */
@media (max-width: 767px) {
  .primarybtn,
  .secondarybtn,
  .whitebtn,
  .lighbgbtn {
    font-size: 14px;
    padding: 10px 20px;
  }
  .hero-sec h1 {
    font-size: 30px;
  }
  .twobutton {
    padding-top: 0px;
  }
  .heroimg img {
    width: 318px;
  }
  .futuresec_main {
    padding: 20px 0;
  }
  .commantext h4 {
    font-size: 22px;
  }
  .commantext p {
    font-size: 14px;
  }
  .commanOther h4 {
    font-size: 18px;
  }
  .commanOther p {
    font-size: 14px;
  }
  .superIcon img {
    width: 60px;
  }
  .mxprofit-hero img {
    width: 280px;
  }
  .becomemain {
    padding: 16px 0;
  }
  /* .accordsec {
    padding: 20px 0;
  } */

  .enjoytext {
    max-width: unset;
  }
  .enjoytext h5 {
    font-size: 18px;
  }
  .logo-detail p {
    font-size: 14px;
  }
  .link-detail img {
    width: 170px;
  }
  .link-detail h6 {
    margin-bottom: 6px;
  }
  .link-detail p {
    margin-bottom: 2px;
  }
  .logodetail_main {
    padding-bottom: 22px;
  }
  .link-detail p a {
    font-size: 14px;
  }
  .logodetail_main {
    flex-direction: column;
  }
  .logodetail_main h4 {
    font-size: 20px;
  }
  .earn_card {
    padding: 14px;
    border-radius: 12px;
  }
  .earn_card img {
    border-radius: 12px;
  }
  .earn_card_main {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    row-gap: 30px;
  }
  .enjoysub {
    margin: 0;
    flex-direction: column;
    gap: 10px;
    align-items: start;
    border-radius: 12px;
  }
  .enjoymain {
    margin-bottom: -80px;
  }
  /* .play_card {
    align-items: start;
  } */
  .playcard_image img {
    width: unset;
  }
  .policy_text .commanOther h4 {
    font-size: 18px;
  }
  .emailspace {
    margin-bottom: 20px !important;
  }

  .form_field .form-control {
    padding: 6px;
  }
}

/* 525 */
@media (max-width: 575px) {
  .hero-sec h1 {
    font-size: 26px;
  }
  .earn_card_main {
    grid-template-columns: repeat(1, 313px);
    gap: 31px;
  }
  .play_cardtext {
    width: 70%;
  }
  .play_cardtext h4 {
    font-size: 18px;
    margin-bottom: 8px;
  }
  .play_cardtext p {
    margin-bottom: 8px;
    font-size: 14px;
  }
  .modal_header {
    padding-bottom: 00px;
  }
  .modal_header h4 {
    font-size: 18px;
  }
  .modal-content {
    width: 90%;
    padding: 10px;
  }
  .form_field {
    padding-bottom: 6px;
  }
  .form_field label {
    font-size: 14px;
  }
}

/* 480 */
@media (max-width: 524px) {
  .herosec_main {
    padding: 84px 0 182px;
  }

  .hero-sec h1 {
    font-size: 24px;
  }
  .heroimg img {
    width: 280px;
  }
  .commantext h4 {
    font-size: 20px;
  }
  .tradingsec {
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
  }
  .commanOther h4 {
    font-size: 16px;
  }
  .user_numbersub img {
    width: 41px;
  }
  .user_numbersub h5 {
    font-size: 16px;
  }
  .user_numbersub p {
    font-size: 14px;
  }
  .startmain {
    padding: 14px 0;
  }
  .trading_sub {
    gap: 10px;
  }
  .maximum_profirsec {
    padding: 18px 0;
  }
  .enjoytext p {
    font-size: 14px;
  }
  .accordsec .accordion-body {
    font-size: 14px;
    padding: 10px;
  }
  .accordsec .accordion-button {
    padding: 10px;
    gap: 10px;
  }
  .link-detail p {
    margin-bottom: 4px;
  }
  .link-detail h6 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .copy-right p {
    font-size: 14px;
  }
  .link-detail p a {
    font-size: 14px;
  }
  .play_cardtext {
    padding: 11px;
    width: 90%;
  }
  .playcard_image img {
    width: 253px;
    border-radius: 22px 0 0px 0px;
  }
  .play_cardtext h4 {
    font-size: 16px;
    margin-bottom: 0px;
  }
  .swipermain {
    padding-bottom: 30px;
  }
  .swiper-pagination {
    bottom: 0 !important;
  }
  .playcard_image {
    height: 100%;
    max-height: 100%;
  }
  .play_cardtext h4 {
    font-size: 14px;
  }
  .play_cardtext p {
    margin-bottom: 6px;
    font-size: 12px;
  }
  .play_cardtext button {
    padding: 6px 10px;
    border-radius: 4px;
  }
  .policy_text .commanOther h4 {
    font-size: 16px;
  }
}

/* 425 */
@media (max-width: 479px) {
  .main-header {
    padding: 10px 0;
  }
  .navbar-items {
    top: 57px;
  }
  .logo img {
    width: 150px;
  }
  .bars img {
    width: 24px;
  }
  .navbar-items ul {
    padding: 0px 4px 22px;
  }
  .hero-sec h1 {
    font-size: 22px;
  }
  .hero-sec p {
    font-size: 14px;
  }
  .twobutton .primarybtn,
  .secondarybtn {
    padding: 8px 18px;
    font-size: 12px;
  }
  .futuresec_main {
    padding: 10px 0 20px;
  }
  .commantext h4 {
    font-size: 18px;
  }
  .tradingIcon img {
    width: 55px;
  }
  .trading_sub {
    padding: 10px;
  }
  .commanOther h4 {
    margin-bottom: 0;
  }
  .superIcon img {
    width: 50px;
    padding-bottom: 10px;
  }
  .mxprofit-hero img {
    width: 250px;
  }
  .accordsec .accordion-button {
    font-size: 14px;
  }
  .enjoytext h5 {
    font-size: 16px;
  }
  .enjoytext .whitebtn {
    border-radius: 6px;
  }
  .earn_card {
    padding: 12px;
    border-radius: 12px;
  }
  .earn_card img {
    border-radius: 12px;
  }
  .earn_card .knowbtn {
    bottom: -23px;
  }
  .earn_card_main {
    margin-top: 4px;
  }
  .earnmain {
    padding: 15px 0 50px;
  }
  .enjoysub {
    padding: 14px;
  }
  .earn_card p {
    font-size: 14px;
  }
  .logodetail_main h4 {
    font-size: 16px;
  }
  .logodetail_main h4 {
    font-size: 16px;
  }
  .link-detail img {
    width: 130px;
    margin-bottom: 10px !important;
  }
  .link-detail p {
    margin-bottom: 0;
  }
}

/* 375 */
@media (max-width: 424px) {
  .primarybtn,
  .secondarybtn,
  .whitebtn,
  .lighbgbtn {
    font-size: 12px;
    padding: 8px 16px;
  }
  .herosec_main {
    padding: 68px 0 182px;
  }
  .hero-sec h1 {
    font-size: 20px;
  }
  .heroimg img {
    width: 280px;
  }
  .link-detail h6 {
    font-size: 14px;
    margin-bottom: 8px;
  }
  .user_numbersub {
    gap: 5px;
  }
  .user_detail {
    justify-content: space-between;
  }
  .earn_card_main {
    grid-template-columns: repeat(1, 1fr);
  }
  .maximum_profirsec {
    padding: 18px 0 0;
  }
  .copy-right p {
    font-size: 12px;
  }
  /* .copy-right{
    padding: 10px 0;
  } */

  .playcard_image img {
    border-radius: 12px 0 0px 12px;
  }
}

/* 320 */
@media (max-width: 374px) {
  .heroimg img {
    width: 260px;
  }
  .user_numbersub img {
    width: 34px;
  }
  .user_numbersub h5 {
    font-size: 14px;
  }
  .user_numbersub p {
    font-size: 12px;
  }
}

/* 280 */
@media (max-width: 319px) {
}
